<template>
  <!-- 抽盒商品 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
      <div class="retrieveDiv flex flex-ac">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">图片名称：</span>
          <el-input class="w-250" v-model="retrieveForm.imgName" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">系列URL：</span>
          <el-input class="w-250" v-model="retrieveForm.img" clearable></el-input>
        </div>
        <div>
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.img" alt="" class="tableImg">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
    <!-- 新增修改弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="图片名称" prop="imgName" v-if="'imgName' in ruleForm"
          :rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.imgName"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="img" v-if="'img' in ruleForm"
          :rules="{required: true, message: '图片不能为空', trigger: 'blur'}">
          <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
            iconName="el-icon-upload" iconSize="26" v-model="ruleForm.img"></upload-file>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'draws',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
        imgName: '',
        img: '',
      },
      butLoading: false,
      tabLoading: false,
      columns: [
        // { type: 'selection', align: 'center', width: "55", fixed: 'left' },
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { columnType: 'custom', label: '图片', prop: 'img', align: 'left', width: '100' },
        { label: '图片名称', prop: 'imgName', align: 'left', width: '200' },
        { label: '图片', prop: 'img', align: 'left', 'show-overflow-tooltip': true },
        { label: '图片后缀', prop: 'number', align: 'left', width: '250' },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogTitle: '新增',
      ruleForm: {
        imgName: '',//轮播图名称
        img: '',  //图片地址
      },
      seriesList: [],//商品系列列表
    }
  },
  mounted () {
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/frontImg/findFrontImgList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          imgName: this.retrieveForm.imgName,
          img: this.retrieveForm.img,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //新增列表
    addList () {
      this.dialogTitle = '新增'
      this.dialogFormVisible = true
    },
    //编辑列表
    editList (row) {
      this.dialogTitle = '编辑'
      this.dialogFormVisible = true
      this.ruleForm = JSON.parse(JSON.stringify(row))   //排序
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ""
          if (this.dialogTitle == '新增') {
            apiUrl = "/frontImg/addFrontImg"
          } else if (this.dialogTitle == '编辑') {
            apiUrl = "/frontImg/editFrontImg"
          }
          this.$http.post(apiUrl, this.ruleForm).then((result) => {
            this.getTableList()
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.butLoading = false
            this.dialogFormVisible = false
          }).catch((err) => {
            this.butLoading = false
          })
        }
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/frontImg/removeFrontImg', { id: row.id }).then(({ data: result }) => {
          this.getTableList()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //关闭弹框
    resetForm () {
      Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate() //清除校验
      })
    },
    //表格跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>